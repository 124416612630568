import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '~/common/utils/styles';
import { Typography } from '../Typography';

export const inputVariants = cva(
    'flex rounded-xsm py-[14px] px-[16px] text-[18px] placeholder:text-gray-500 focus:outline-none focus:ring-0',
    {
        variants: {
            variant: {
                primary:
                    'bg-black/[0.04] border border-transparent hover:bg-white hover:border-black/[0.16] focus:border focus:border-black focus:bg-white disabled:bg-white disabled:border-black/[0.07]',
            },
            inputSize: {
                large: 'max-h-[56px]',
                medium: 'max-h-[44px]',
                small: 'max-h-[36px]',
            },
            error: {
                true: 'border border-red-600 bg-red-25 hover:border-red-600 hover:bg-red-25 focus:border-red-600 focus:bg-red-25 focus:ring-2 focus:ring-offset-2 focus:ring-red-300  disabled:bg-red-25',
            },
        },
        defaultVariants: {
            variant: 'primary',
            inputSize: 'large',
        },
    }
);

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement>,
        VariantProps<typeof inputVariants> {
    message?: string;
    prefixComponent?: React.ReactNode;
    suffixComponent?: React.ReactNode;
    wrapperClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            wrapperClassName = '',
            className = '',
            variant,
            inputSize,
            message,
            error,
            prefixComponent,
            suffixComponent,
            ...props
        },
        ref
    ) => {
        return (
            <>
                <div
                    className={cn(
                        'relative flex items-center',
                        wrapperClassName
                    )}
                >
                    {prefixComponent && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                            {prefixComponent}
                        </div>
                    )}
                    <input
                        className={cn(
                            inputVariants({ variant, inputSize, error }),
                            'pl-[16px]',
                            prefixComponent && 'pl-[42px]',
                            suffixComponent && 'pr-[52px]',
                            className
                        )}
                        ref={ref}
                        {...props}
                    />
                    {suffixComponent && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[16px]">
                            {suffixComponent}
                        </div>
                    )}
                </div>
                {message && (
                    <Typography
                        intent="body"
                        size="xs"
                        as="span"
                        className="text-gray-500"
                    >
                        {message}
                    </Typography>
                )}
            </>
        );
    }
);
Input.displayName = 'Input';

export { Input };
